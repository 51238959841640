var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-card',[_c('v-card-text',[_c(_setup.ReturnLink),(_vm.initializing)?_c('v-skeleton-loader',{attrs:{"type":"card-heading","data-cy":"assignment-name-skeleton-loader"}}):_c('div',{staticClass:"d-flex flex-row align-center justify-space-between"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"text-h3 text-truncate",attrs:{"role":"heading","data-cy":"assignment-name"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_setup.assignment?.name)+" ")])]}}])},[_vm._v(" "+_vm._s(_setup.assignment?.name)+" ")]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"8px"}},[(_setup.timeLimitInMinutes)?_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v("Time limit")]),_c('span',{staticClass:"text-decoration-none primary--text text--darken-2",attrs:{"data-cy":"time-limit-minutes"}},[_vm._v(" "+_vm._s(_setup.timeLimitInMinutes)+" minutes ")])]):_vm._e(),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","medium":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","data-cy":"student-preview-btn","href":_setup.previewLink,"target":"blank"},on:{"click":_setup.trackPreviewAsStudent}},[_vm._v(" Preview Assignment as Student ")])],1)],1)],1)],1)],1),(!_vm.initializing && _setup.reportType != _setup.ReportType.SKILLBUILDER)?_c('v-tabs',{attrs:{"color":"primary darken-2","dense":""}},[_c('v-tabs-slider',{attrs:{"color":"primary darken-2"}}),_c('v-tab',{attrs:{"to":{
          name: 'problemsReportPage',
          query: { ..._setup.route.query },
        },"data-cy":"problems-tab"}},[_vm._v(" Problems ")]),_c('v-tab',{attrs:{"to":{
          name: 'standardsReportPage',
          query: { ..._setup.route.query },
        },"data-cy":"standards-tab"}},[_vm._v(" Standards ")])],1):_vm._e()],1),_c('router-view',{attrs:{"assignment":_setup.assignment,"assignees":_vm.assignees,"studentData":_vm.studentData,"problemStatsMap":_vm.problemStatsMap,"problemSetsMap":_vm.problemSetsMap,"tutorStrategiesMap":_vm.tutorStrategiesMap,"quickCommentsMap":_vm.quickCommentsMap,"problems":_vm.problems,"initializing":_vm.initializing,"downloading":_vm.downloading,"hasRedo":_vm.hasRedo,"testMode":_vm.testMode,"anonymize":_vm.anonymize,"updating":_vm.updating},on:{"deleteProgress":_setup.deleteProgress,"updateLog":(...args) => _setup.emit('updateLog', ...args),"goToStudentDetails":_setup.goToStudentDetailsPage,"uploadScores":function($event){_setup.uploadDialog = true}}}),_c(_setup.UploadScoresDialog,{attrs:{"assignment":_setup.assignment},on:{"uploaded":_setup.trackReportUpload},model:{value:(_setup.uploadDialog),callback:function ($$v) {_setup.uploadDialog=$$v},expression:"uploadDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }