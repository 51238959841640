/* eslint-disable @typescript-eslint/ban-types */
import { Server } from 'miragejs';
import dayjs from 'dayjs';
import { AssigneeType, AssignmentScope } from '@/domain/Assignment';
import { faker } from '@faker-js/faker';
import { AssignmentDefinitionDTO } from '@/api/core/assignment.api';
import {
  checkLogin,
  hasOneOfTheseAuthorities,
  isLoggedIn,
  mockUser,
} from '../server';
import { AclPermissionType } from '@/domain/Acls';
import { UserRole } from '@/domain/User';

faker.seed(0);

export function transformMirageAssignmentDefinition(
  assignment: AssignmentDefinitionDTO
): AssignmentDefinitionDTO {
  // FIXME: Handle ACLs lookup. If not, abstract code.
  checkLogin();
  const permissions = [];
  if (isLoggedIn.status) {
    // If mock User is Admin or Owner, give ALL Permissions.
    if (hasOneOfTheseAuthorities([UserRole.SERVICE_ADMIN])) {
      permissions.push(AclPermissionType.UPDATE, AclPermissionType.DELETE);
    } else if (assignment.ownerXref == mockUser.data.xref) {
      permissions.push(AclPermissionType.UPDATE, AclPermissionType.DELETE);
    }
  }
  return {
    ...assignment,
    permissions,
  };
}

export default function (server: Server): void {
  server.create('assignmentDefinition', {
    scope: AssignmentScope.PRINCIPAL_GROUP,
    assigneeType: AssigneeType.GROUP,
    xref: 'wpiG6PSFf0aguy2P0bw9',
    assigneeXref: '1',
    ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
    groupContextXref: '1',
    problemSetCeri: 'PSABHT54',
    lmsProviderType: 'GOOGLE_CLASSROOM',
    name: '6.1 Lesson 1 Student Facing Task - Tiling the Plane (6.G.A.1)',
    assignDate: dayjs().subtract(2, 'week').valueOf(), // assigned two weeks ago
    releaseDate: dayjs().subtract(2, 'week').valueOf(),
    dueDate: dayjs().subtract(3, 'days').valueOf(), // due three days ago
    active: true,
    properties: [
      {
        categoryName: 'TIME_LIMIT',
        categoryDescription: 'Time Limit',
        propertyKey: 'timeLimit',
        propertyDescription: 'Time Limit',
        propertyValue: 180,
      },
      {
        categoryName: 'USE_REDO',
        categoryDescription: 'Use Redo',
        propertyKey: 'useRedo',
        propertyDescription:
          'When true, enables the student experience to deliver similar problems',
        propertyValue: false,
      },
    ],
    skills: ['SKBW', 'SKB7'],
  } as object);
  server.create('assignmentDefinition', {
    scope: AssignmentScope.PRINCIPAL_GROUP,
    assigneeType: AssigneeType.GROUP,
    xref: '9JnFP7A4K853S3d9HsF2',
    assigneeXref: '2',
    ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
    groupContextXref: '2',
    problemSetCeri: 'PSABS7K4',
    lmsProviderType: 'GOOGLE_CLASSROOM',
    name: '6.3 Lesson 2: Anchoring Units of Measurement (6.RP.A.3.d)',
    assignDate: dayjs().valueOf(),
    releaseDate: dayjs().subtract(1, 'day').valueOf(),
    dueDate: dayjs().add(1, 'week').valueOf(),
    active: false,
    properties: [
      {
        categoryName: 'TIME_LIMIT',
        categoryDescription: 'Time Limit',
        propertyKey: 'timeLimit',
        propertyDescription: 'Time Limit',
        propertyValue: 180,
      },
      {
        categoryName: 'USE_REDO',
        categoryDescription: 'Use Redo',
        propertyKey: 'useRedo',
        propertyDescription:
          'When true, enables the student experience to deliver similar problems',
        propertyValue: 'true',
      },
    ],
    skills: ['SKBBA'],
  } as object);
  server.create('assignmentDefinition', {
    scope: AssignmentScope.PRINCIPAL_GROUP,
    assigneeType: AssigneeType.GROUP,
    xref: 'Or1tJNwlmCkwGjnUcbvX',
    assigneeXref: '3',
    ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
    groupContextXref: '3',
    problemSetCeri: 'PSABHT5A',
    lmsProviderType: 'CANVAS',
    name: 'TEST MODE: 6.1 Lesson 2: Finding Area by Decomposing and Rearranging (6.G.A.1)',
    assignDate: dayjs().subtract(1, 'day').valueOf(),
    releaseDate: dayjs().add(2, 'week').valueOf(),
    dueDate: dayjs().add(1, 'month').valueOf(),
    active: true,
    properties: [
      {
        categoryName: 'TEST_MODE',
        categoryDescription: 'Correctness Feedback',
        propertyKey: 'correctnessFeedback',
        propertyDescription: 'Correctness Feedback',
        propertyValue: 'false',
      },
      {
        categoryName: 'TEST_MODE',
        categoryDescription: 'Tutoring Accessible',
        propertyKey: 'tutoringAccessible',
        propertyDescription: 'Tutoring Accessible',
        propertyValue: 'false',
      },
    ],
    // properties: {
    //   correctnessFeedback: false,
    //   tutoringAccessible: false,
    // },
    skills: ['SKBJP', 'SKB7'],
  } as object);
  server.create('assignmentDefinition', {
    scope: AssignmentScope.PRINCIPAL_GROUP,
    assigneeType: AssigneeType.GROUP,
    xref: 'G0MJJi02j3zSo9tOH2lC',
    assigneeXref: '2',
    ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
    groupContextXref: '2',
    problemSetCeri: 'PSA6N9K',
    lmsProviderType: 'CANVAS',
    name: '6.1 Lesson 2: Finding Area by Decomposing and Rearranging (6.G.A.1)',
    assignDate: dayjs().subtract(3, 'week').valueOf(),
    releaseDate: dayjs().subtract(2, 'week').valueOf(),
    dueDate: dayjs().subtract(1, 'week').valueOf(),
    active: false,
    properties: [
      {
        categoryName: 'TIME_LIMIT',
        categoryDescription: 'Time Limit',
        propertyKey: 'timeLimit',
        propertyDescription: 'Time Limit',
        propertyValue: '0',
      },
    ],
    skills: ['SKBJP', 'SKB7'],
  } as object);
  server.create('assignmentDefinition', {
    scope: AssignmentScope.PRINCIPAL_GROUP,
    assigneeType: AssigneeType.GROUP,
    xref: 'TEST_XREF_1234',
    assigneeXref: '1',
    ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
    groupContextXref: '1',
    problemSetCeri: 'PSABHT54', // FIXME: ProblemXref?
    lmsProviderType: 'GOOGLE_CLASSROOM',
    name: 'TEST: Student Details report',
    assignDate: dayjs().valueOf(),
    releaseDate: dayjs().valueOf(),
    dueDate: null,
    active: true,
  } as object);
  server.create('assignmentDefinition', {
    scope: AssignmentScope.PRINCIPAL_GROUP,
    assigneeType: AssigneeType.GROUP,
    xref: 'GnhVZMxlBpkoRViC9KgL',
    assigneeXref: '3',
    ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
    groupContextXref: '3',
    problemSetCeri: 'PSABHT5A',
    lmsProviderType: 'CANVAS',
    name: '(LEGACY) TEST MODE: 6.1 Lesson 2: Finding Area by Decomposing and Rearranging (6.G.A.1)',
    assignDate: dayjs().subtract(1, 'day').valueOf(),
    releaseDate: dayjs().add(2, 'week').valueOf(),
    dueDate: dayjs().add(1, 'month').valueOf(),
    active: true,
    properties: [
      {
        categoryName: 'TEST_MODE',
        categoryDescription: 'Correctness Feedback',
        propertyKey: 'correctnessFeedback',
        propertyDescription: 'Correctness Feedback',
        propertyValue: 'false',
      },
      {
        categoryName: 'TEST_MODE',
        categoryDescription: 'Tutoring Accessible',
        propertyKey: 'tutoringAccessible',
        propertyDescription: 'Tutoring Accessible',
        propertyValue: 'false',
      },
    ],
    skills: ['SKBJP', 'SKB7'],
  } as object);
  server.create('assignmentDefinition', {
    scope: AssignmentScope.PRINCIPAL_GROUP,
    assigneeType: AssigneeType.GROUP,
    xref: 'kbpqEqpkycnPaAsRhlJ0',
    assigneeXref: '1',
    ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
    groupContextXref: '1',
    problemSetCeri: 'PSABSY9D.8551476',
    name: "Cathryn's Redo",
    assignDate: 1679679102552,
    releaseDate: 1679679102552,
    isActive: true,
    properties: [
      {
        propertyKey: 'timeLimit',
        propertyDescription: 'Sets assignment time limit in seconds',
        propertyValue: '180',
      },
      {
        propertyKey: 'useRedo',
        propertyDescription:
          'When true, enables the student experience to deliver similar problems',
        propertyValue: 'true',
      },
      {
        propertyKey: 'deliveryOfStudentScore',
        propertyDescription: 'Defining score delivery',
        propertyValue: 'true',
      },
      {
        propertyKey: 'assignmentHasExperiment',
        propertyDescription: 'If an assignment has an experiment',
        propertyValue: 'false',
      },
      {
        propertyKey: 'liveTutoringExtPartner',
        propertyDescription:
          'Live Tutoring External Partner (used for url in the tutor)',
        propertyValue: 'Upchieve',
      },
      {
        propertyKey: 'liveTutoringInsteadOfTutoring',
        propertyDescription:
          'Live Tutoring Button replaces the Original Tutoring Button',
        propertyValue: 'false',
      },
      {
        propertyKey: 'testModeStudentReport',
        propertyDescription: 'Enable Test Mode Student Reports',
        propertyValue: 'false',
      },
      {
        propertyKey: 'liveTutoringEnabled',
        propertyDescription:
          'Is the live tutoring button available to students',
        propertyValue: 'true',
      },
      {
        propertyKey: 'personalizedTutoringEnabled',
        propertyDescription: 'enable personalized tutoring',
        propertyValue: 'true',
      },
      {
        propertyKey: 'starredTeacherEnabled',
        propertyDescription: 'automatically subscribed to starred teachers',
        propertyValue: 'true',
      },
      {
        propertyKey: 'teacherAssistEnabled',
        propertyDescription:
          'enable an option to create their own teacherASSIST',
        propertyValue: 'false',
      },
      {
        propertyKey: 'partialCredit',
        propertyDescription: 'Is partial credit enabled',
        propertyValue: 'true',
      },
      {
        propertyKey: 'hintDelay',
        propertyDescription: 'Number of seconds between each hint',
        propertyValue: '0',
      },
      {
        propertyKey: 'partialCreditMaxAttempts',
        propertyDescription:
          'Max attempts and hints used before partial credit score goes to 0',
        propertyValue: '3',
      },
      {
        propertyKey: 'useCait',
        propertyDescription: 'When true, the student has requested CAIT',
        propertyValue: 'true',
      },
    ],
    skills: ['SKBJQ', 'SKBJR'],
  } as object);
  server.create('assignmentDefinition', {
    scope: 'GROUP',
    assigneeType: 'GROUP',
    xref: 'e6121fbd-c9b5-4c2f-8ba6-83bf80de87da',
    assigneeXref: '1',
    ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
    groupContextXref: '1',
    problemSetCeri: 'PSABXACC',
    lmsProviderType: 'GOOGLE_CLASSROOM',
    name: 'Redo Assignment (e6121fbd-c9b5-4c2f-8ba6-83bf80de87da)',
    assignDate: 1695776506172,
    releaseDate: 1695776506172,
    isActive: true,
    properties: [
      {
        propertyKey: 'timeLimit',
        propertyDescription: 'Sets assignment time limit in seconds',
        propertyValue: '0',
      },
      {
        propertyKey: 'useRedo',
        propertyDescription:
          'When true, enables the student experience to deliver similar problems',
        propertyValue: 'true',
      },
      {
        propertyKey: 'deliveryOfStudentScore',
        propertyDescription: 'Defining score delivery',
        propertyValue: 'true',
      },
      {
        propertyKey: 'assignmentHasExperiment',
        propertyDescription: 'If an assignment has an experiment',
        propertyValue: 'false',
      },
      {
        propertyKey: 'liveTutoringExtPartner',
        propertyDescription:
          'Live Tutoring External Partner (used for url in the tutor)',
        propertyValue: 'Upchieve',
      },
      {
        propertyKey: 'liveTutoringInsteadOfTutoring',
        propertyDescription:
          'Live Tutoring Button replaces the Original Tutoring Button',
        propertyValue: 'false',
      },
      {
        propertyKey: 'testModeStudentReport',
        propertyDescription: 'Enable Test Mode Student Reports',
        propertyValue: 'false',
      },
      {
        propertyKey: 'liveTutoringEnabled',
        propertyDescription:
          'Is the live tutoring button available to students',
        propertyValue: 'true',
      },
      {
        propertyKey: 'personalizedTutoringEnabled',
        propertyDescription: 'enable personalized tutoring',
        propertyValue: 'true',
      },
      {
        propertyKey: 'starredTeacherEnabled',
        propertyDescription: 'automatically subscribed to starred teachers',
        propertyValue: 'true',
      },
      {
        propertyKey: 'teacherAssistEnabled',
        propertyDescription:
          'enable an option to create their own teacherASSIST',
        propertyValue: 'false',
      },
      {
        propertyKey: 'partialCredit',
        propertyDescription: 'Is partial credit enabled',
        propertyValue: 'true',
      },
      {
        propertyKey: 'hintDelay',
        propertyDescription: 'Number of seconds between each hint',
        propertyValue: '0',
      },
      {
        propertyKey: 'partialCreditMaxAttempts',
        propertyDescription:
          'Max attempts and hints used before partial credit score goes to 0',
        propertyValue: '3',
      },
    ],
    skills: ['SKBJS', 'SKBJT'],
  } as object);
  server.create('assignmentDefinition', {
    scope: 'GROUP',
    assigneeType: 'GROUP',
    xref: 'ESSAY_SCORING_ASSIGNMENT',
    assigneeXref: '1',
    ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
    groupContextXref: '1',
    problemSetCeri: 'PSABSVSE',
    lmsProviderType: 'GOOGLE_CLASSROOM',
    name: 'Open Response Assignment (708ceb49-1459-4616-9530-31b3ab3efa50)',
    assignDate: 1674662004091,
    releaseDate: 1674662004091,
    isActive: true,
    properties: [
      {
        propertyKey: 'useRedo',
        propertyDescription:
          'When true, enables the student experience to deliver similar problems',
        propertyValue: 'false',
      },
      {
        propertyKey: 'deliveryOfStudentScore',
        propertyDescription: 'Defining score delivery',
        propertyValue: 'false',
      },
      {
        propertyKey: 'assignmentHasExperiment',
        propertyDescription: 'If an assignment has an experiment',
        propertyValue: 'false',
      },
      {
        propertyKey: 'liveTutoringExtPartner',
        propertyDescription:
          'Live Tutoring External Partner (used for url in the tutor)',
        propertyValue: 'Upchieve',
      },
      {
        propertyKey: 'liveTutoringInsteadOfTutoring',
        propertyDescription:
          'Live Tutoring Button replaces the Original Tutoring Button',
        propertyValue: 'false',
      },
      {
        propertyKey: 'testModeStudentReport',
        propertyDescription: 'Enable Test Mode Student Reports',
        propertyValue: 'false',
      },
      {
        propertyKey: 'liveTutoringEnabled',
        propertyDescription:
          'Is the live tutoring button available to students',
        propertyValue: 'true',
      },
      {
        propertyKey: 'personalizedTutoringEnabled',
        propertyDescription: 'enable personalized tutoring',
        propertyValue: 'true',
      },
      {
        propertyKey: 'starredTeacherEnabled',
        propertyDescription: 'automatically subscribed to starred teachers',
        propertyValue: 'true',
      },
      {
        propertyKey: 'teacherAssistEnabled',
        propertyDescription:
          'enable an option to create their own teacherASSIST',
        propertyValue: 'false',
      },
      {
        propertyKey: 'partialCredit',
        propertyDescription: 'Is partial credit enabled',
        propertyValue: 'true',
      },
      {
        propertyKey: 'hintDelay',
        propertyDescription: 'Number of seconds between each hint',
        propertyValue: '0',
      },
      {
        propertyKey: 'partialCreditMaxAttempts',
        propertyDescription:
          'Max attempts and hints used before partial credit score goes to 0',
        propertyValue: '3',
      },
    ],
    skills: ['SKBJX', 'SKBKV', 'SKBCH'],
  } as object);

  const standardNodes = server.schema.db.standardNodes;
  const psCeris = ['PSA6N9K', 'PSABSY9D.8551476', 'PSABHT5A', 'PSA1389966'];

  for (let i = 1; i <= 30; i++) {
    server.create('assignmentDefinition', {
      scope: AssignmentScope.PRINCIPAL_GROUP,
      assigneeType: AssigneeType.GROUP,
      xref: `GeneratedAssignment-${i}`,
      assigneeXref: '1',
      ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
      groupContextXref: '1',
      problemSetCeri: psCeris[i % 4],
      lmsProviderType: 'GOOGLE_CLASSROOM',
      name: `
      ${i % 4 == 0 ? 'Exit Ticket --- ' : ''}
      ${i % 5 == 0 ? 'Cool Down --- ' : ''}
      ${i % 6 == 0 ? 'Diagnostic --- ' : ''}
      Lesson ${i}: ${faker.word.adjective() + ' ' + faker.word.noun()}`,
      assignDate: dayjs().subtract(i, 'day').valueOf(),
      releaseDate: dayjs().subtract(i, 'day').valueOf(),
      dueDate: dayjs()
        .subtract(i + 1, 'day')
        .valueOf(),
      skills:
        i < 29
          ? [...standardNodes[i].skills, ...standardNodes[i + 1].skills]
          : standardNodes[i].skills,
      active: true,
      properties: [
        {
          propertyKey: 'timeLimit',
          propertyDescription: 'Sets assignment time limit in seconds',
          propertyValue: '180',
        },
        {
          propertyKey: 'useRedo',
          propertyDescription:
            'When true, enables the student experience to deliver similar problems',
          propertyValue: 'true',
        },
        {
          propertyKey: 'deliveryOfStudentScore',
          propertyDescription: 'Defining score delivery',
          propertyValue: 'true',
        },
        {
          propertyKey: 'assignmentHasExperiment',
          propertyDescription: 'If an assignment has an experiment',
          propertyValue: 'false',
        },
        {
          propertyKey: 'liveTutoringExtPartner',
          propertyDescription:
            'Live Tutoring External Partner (used for url in the tutor)',
          propertyValue: 'Upchieve',
        },
        {
          propertyKey: 'liveTutoringInsteadOfTutoring',
          propertyDescription:
            'Live Tutoring Button replaces the Original Tutoring Button',
          propertyValue: 'false',
        },
        {
          propertyKey: 'testModeStudentReport',
          propertyDescription: 'Enable Test Mode Student Reports',
          propertyValue: 'false',
        },
        {
          propertyKey: 'liveTutoringEnabled',
          propertyDescription:
            'Is the live tutoring button available to students',
          propertyValue: 'true',
        },
        {
          propertyKey: 'personalizedTutoringEnabled',
          propertyDescription: 'enable personalized tutoring',
          propertyValue: 'true',
        },
        {
          propertyKey: 'starredTeacherEnabled',
          propertyDescription: 'automatically subscribed to starred teachers',
          propertyValue: 'true',
        },
        {
          propertyKey: 'teacherAssistEnabled',
          propertyDescription:
            'enable an option to create their own teacherASSIST',
          propertyValue: 'false',
        },
        {
          propertyKey: 'partialCredit',
          propertyDescription: 'Is partial credit enabled',
          propertyValue: 'true',
        },
        {
          propertyKey: 'hintDelay',
          propertyDescription: 'Number of seconds between each hint',
          propertyValue: '0',
        },
        {
          propertyKey: 'partialCreditMaxAttempts',
          propertyDescription:
            'Max attempts and hints used before partial credit score goes to 0',
          propertyValue: '3',
        },
      ]
        .sort(() => 0.5 - Math.random())
        .slice(0, 2),
    } as object);
    server.create('assignmentDefinition', {
      scope: 'GROUP',
      assigneeType: 'GROUP',
      xref: '61623176-3f3c-4423-a20d-ec499042276a',
      assigneeXref: '1',
      ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
      groupContextXref: '1',
      problemSetCeri: 'PSABS7KB',
      lmsProviderType: 'CANVAS',
      name: 'Late Assignment (61623176-3f3c-4423-a20d-ec499042276a)',
      assignDate: 1721916138760,
      releaseDate: 1721916138760,
      dueDate: 1721916180000,
      isActive: true,
      properties: [
        {
          propertyKey: 'useCait',
          propertyDescription:
            'Turning on CAIT will enable our internal AI chat assistant. All other supports will be disabled when this option is selected.',
          propertyValue: 'false',
        },
        {
          propertyKey: 'useTeachley',
          propertyDescription:
            'When true, will deliver Teachley content for showing student work where available',
          propertyValue: 'false',
        },
        {
          propertyKey: 'useQuickCommentsLive',
          propertyDescription:
            'When true student workbench will request to quickcomments',
          propertyValue: 'false',
        },
        {
          propertyKey: 'assignedByTutor',
          propertyDescription: 'the assignment was created by a tutor',
          propertyValue: 'false',
        },
        {
          propertyKey: 'timeLimit',
          propertyDescription: 'Sets assignment time limit in seconds',
          propertyValue: '0',
        },
        {
          propertyKey: 'useRedo',
          propertyDescription:
            'When true, enables the student experience to deliver similar problems',
          propertyValue: 'false',
        },
        {
          propertyKey: 'deliveryOfStudentScore',
          propertyDescription: 'Defining score delivery',
          propertyValue: 'false',
        },
        {
          propertyKey: 'assignmentHasExperiment',
          propertyDescription: 'If an assignment has an experiment',
          propertyValue: 'false',
        },
        {
          propertyKey: 'liveTutoringExtPartner',
          propertyDescription:
            'Live Tutoring External Partner (used for url in the tutor)',
          propertyValue: '',
        },
        {
          propertyKey: 'liveTutoringInsteadOfTutoring',
          propertyDescription:
            'Live Tutoring Button replaces the Original Tutoring Button',
          propertyValue: 'false',
        },
        {
          propertyKey: 'testModeStudentReport',
          propertyDescription: 'Enable Test Mode Student Reports',
          propertyValue: 'false',
        },
        {
          propertyKey: 'liveTutoringEnabled',
          propertyDescription:
            'Is the live tutoring button available to students',
          propertyValue: 'false',
        },
        {
          propertyKey: 'personalizedTutoringEnabled',
          propertyDescription: 'enable personalized tutoring',
          propertyValue: 'false',
        },
        {
          propertyKey: 'starredTeacherEnabled',
          propertyDescription: 'automatically subscribed to starred teachers',
          propertyValue: 'true',
        },
        {
          propertyKey: 'teacherAssistEnabled',
          propertyDescription:
            'enable an option to create their own teacherASSIST',
          propertyValue: 'false',
        },
        {
          propertyKey: 'partialCredit',
          propertyDescription: 'Is partial credit enabled',
          propertyValue: 'true',
        },
        {
          propertyKey: 'hintDelay',
          propertyDescription: 'Number of seconds between each hint',
          propertyValue: '0',
        },
        {
          propertyKey: 'partialCreditMaxAttempts',
          propertyDescription:
            'Max attempts and hints used before partial credit score goes to 0',
          propertyValue: '3',
        },
      ],
      skills: ['SKBMP', 'SKBMQ', 'SKBMB'],
      active: true,
    } as object);
    server.create('assignmentDefinition', {
      scope: 'GROUP',
      assigneeType: 'GROUP',
      xref: '5e7f3084-4f11-414e-8637-d7ff962c4e8e',
      assigneeXref: '1',
      ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
      groupContextXref: '1',
      problemSetCeri: 'PSABC8CA',
      lmsProviderType: 'CANVAS',
      name: 'Assignment with Open Responses (5e7f3084-4f11-414e-8637-d7ff962c4e8e)',
      assignDate: 1724167773978,
      releaseDate: 1724167773978,
      isActive: true,
      properties: [
        {
          propertyKey: 'useCait',
          propertyDescription:
            'Turning on CAIT will enable our internal AI chat assistant. All other supports will be disabled when this option is selected.',
          propertyValue: 'false',
        },
        {
          propertyKey: 'useTeachley',
          propertyDescription:
            'When true, will deliver Teachley content for showing student work where available',
          propertyValue: 'false',
        },
        {
          propertyKey: 'useQuickCommentsLive',
          propertyDescription:
            'When true student workbench will request to quickcomments',
          propertyValue: 'false',
        },
        {
          propertyKey: 'assignedByTutor',
          propertyDescription: 'the assignment was created by a tutor',
          propertyValue: 'false',
        },
        {
          propertyKey: 'timeLimit',
          propertyDescription: 'Sets assignment time limit in seconds',
          propertyValue: '0',
        },
        {
          propertyKey: 'useRedo',
          propertyDescription:
            'When true, enables the student experience to deliver similar problems',
          propertyValue: 'false',
        },
        {
          propertyKey: 'deliveryOfStudentScore',
          propertyDescription: 'Defining score delivery',
          propertyValue: 'false',
        },
        {
          propertyKey: 'assignmentHasExperiment',
          propertyDescription: 'If an assignment has an experiment',
          propertyValue: 'false',
        },
        {
          propertyKey: 'liveTutoringExtPartner',
          propertyDescription:
            'Live Tutoring External Partner (used for url in the tutor)',
          propertyValue: '',
        },
        {
          propertyKey: 'liveTutoringInsteadOfTutoring',
          propertyDescription:
            'Live Tutoring Button replaces the Original Tutoring Button',
          propertyValue: 'false',
        },
        {
          propertyKey: 'testModeStudentReport',
          propertyDescription: 'Enable Test Mode Student Reports',
          propertyValue: 'false',
        },
        {
          propertyKey: 'liveTutoringEnabled',
          propertyDescription:
            'Is the live tutoring button available to students',
          propertyValue: 'false',
        },
        {
          propertyKey: 'personalizedTutoringEnabled',
          propertyDescription: 'enable personalized tutoring',
          propertyValue: 'false',
        },
        {
          propertyKey: 'starredTeacherEnabled',
          propertyDescription: 'automatically subscribed to starred teachers',
          propertyValue: 'true',
        },
        {
          propertyKey: 'teacherAssistEnabled',
          propertyDescription:
            'enable an option to create their own teacherASSIST',
          propertyValue: 'false',
        },
        {
          propertyKey: 'partialCredit',
          propertyDescription: 'Is partial credit enabled',
          propertyValue: 'true',
        },
        {
          propertyKey: 'hintDelay',
          propertyDescription: 'Number of seconds between each hint',
          propertyValue: '0',
        },
        {
          propertyKey: 'partialCreditMaxAttempts',
          propertyDescription:
            'Max attempts and hints used before partial credit score goes to 0',
          propertyValue: '3',
        },
      ],
      skills: ['SKBRD', 'SKBRE'],
      active: true,
    } as object);
    server.create('assignmentDefinition', {
      scope: 'GROUP',
      assigneeType: 'GROUP',
      xref: 'b63363a2-44a0-4eb7-8ef3-ba3aac42600c',
      assigneeXref: '1',
      ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
      groupContextXref: '1',
      problemSetCeri: 'PSBE47S',
      lmsProviderType: 'CANVAS',
      name: 'Test Mode Assignment (b63363a2-44a0-4eb7-8ef3-ba3aac42600c)',
      assignDate: 1724175171090,
      releaseDate: 1724175171090,
      isActive: true,
      properties: [
        {
          propertyKey: 'deliveryOfStudentScore',
          propertyDescription: 'Defining score delivery',
          propertyValue: 'true',
        },
        {
          propertyKey: 'partialCreditMaxAttempts',
          propertyDescription:
            'Max attempts and hints used before partial credit score goes to 0',
          propertyValue: '3',
        },
        {
          propertyKey: 'hintDelay',
          propertyDescription: 'Number of seconds between each hint',
          propertyValue: '0',
        },
        {
          propertyKey: 'partialCredit',
          propertyDescription: 'Is partial credit enabled',
          propertyValue: 'true',
        },
        {
          propertyKey: 'teacherAssistEnabled',
          propertyDescription:
            'enable an option to create their own teacherASSIST',
          propertyValue: 'false',
        },
        {
          propertyKey: 'personalizedTutoringEnabled',
          propertyDescription: 'enable personalized tutoring',
          propertyValue: 'false',
        },
        {
          propertyKey: 'liveTutoringEnabled',
          propertyDescription:
            'Is the live tutoring button available to students',
          propertyValue: 'false',
        },
        {
          propertyKey: 'starredTeacherEnabled',
          propertyDescription: 'automatically subscribed to starred teachers',
          propertyValue: 'true',
        },
        {
          propertyKey: 'liveTutoringInsteadOfTutoring',
          propertyDescription:
            'Live Tutoring Button replaces the Original Tutoring Button',
          propertyValue: 'false',
        },
        {
          propertyKey: 'liveTutoringExtPartner',
          propertyDescription:
            'Live Tutoring External Partner (used for url in the tutor)',
          propertyValue: '',
        },
        {
          propertyKey: 'correctnessFeedback',
          propertyDescription: 'Set correctness for a problem set',
          propertyValue: 'false',
        },
        {
          propertyKey: 'tutoringAccessible',
          propertyDescription: 'Set tutoring for a problem set',
          propertyValue: 'false',
        },
      ],
      skills: ['SKBEG', 'SKBGF', 'SKBGG', 'SKBG8'],
      active: true,
    } as object);
  }
}
