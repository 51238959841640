import {
  IAssignmentAction,
  IProblemAction,
  IProblemSetAction,
  IResponseAction,
  ITimerAction,
  ITutoringRequestedAction,
} from '../Action';

export interface ContentInfo {
  xref: string;
  itemType: 'ASSIGNMENT' | 'PROBLEM_SET';
  active: boolean;
}

export interface CommonWrongAnswer {
  answer: Record<number, string[]>;
  percent: number;
}

/////////////////
// CAS Web API //
/////////////////

export interface ProblemLog {
  id: number;
  prCeri: string;
  startTime: number;
  endTime?: number;
  continuousScore?: number;
  discreteScore?: 0 | 1;
  firstActionType?: FirstActionType;
  attemptCount: number;
  hintCount: number;
  sawAnswer: boolean;
  pathInfo: string;
  firstResponseTime: number;
  redoParentXref?: string;
  partLogData?: Record<number, PartLogData>;
  // Internal processing only. Will not included in DTO if not set manually.
  redoAverageScore?: number;
}

export interface PartLogData {
  response?: string[];
  teacherComment?: string | null;
  hintCount?: number;
  attemptCount?: number;
  continuousScore?: number;
  discreteScore?: 0 | 1;
}

export enum FirstActionType {
  ANSWER = 'ANSWER',
  HINT = 'HINT',
  SCAFFOLD = 'SCAFFOLD',
  ANSWER_REQUESTED = 'ANSWER_REQUESTED',
  EXPLANATION = 'EXPLANATION',
  URL_LINK = 'URL_LINK',
  LIVE_TUTORING_REQUESTED = 'LIVE_TUTORING_REQUESTED',
  CAIT_MESSAGE = 'CAIT_MESSAGE',
}

export interface ProblemLogAndActions {
  prLog: ProblemLog;
  // Order by timestamp in ASC.
  actions?: (IProblemAction | IResponseAction | ITutoringRequestedAction)[];
}

export interface StudentLog {
  studentXref: string;
  asStartTime: number;
  asEndTime?: number;
  // MUST have some but may NOT be requested?
  psActions?: (IAssignmentAction | IProblemSetAction | ITimerAction)[];
  problemLogAndActions?: ProblemLogAndActions[];
  lastWorkedOn: number;
}

export interface ProblemStats {
  prCeri: string;
  cwas?: CommonWrongAnswer[];
  avgScore?: number;
  avgTime?: number;
}

export interface StudentScore {
  studentXref: string;
  score?: number;
}

export interface StudentStats extends StudentScore {
  timeSpent: number;
  percentComplete: number;
  scoreStatus?: ScoreStatus;
}

export interface SummaryStats {
  avgScore?: number;
  avgTime: number;
  studentStats: StudentStats[];
  avgCompletion?: number;
}

export interface StudentData {
  contentInfo: ContentInfo;
  summaryStatsAll?: SummaryStats;
  summaryStatsSubset?: SummaryStats;
  prAllStats?: ProblemStats[];
  prSubsetStats?: ProblemStats[];
  studentLogs?: StudentLog[];
}
export interface StudentDataOverTime {
  summaryStatsAll?: SummaryStats;
  summaryStatsSubset?: SummaryStats;
  assignmentData?: StudentData[];
}

export enum ScoreStatus {
  PENDING = 'PENDING',
  FULLY_SCORED = 'FULLY_SCORED',
}
