import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { CourseState } from './types';
import { RootState } from '../types';

export const state: CourseState = {
  hasDownloaded: false,
  isDownloading: false,
  courses: [],
  courseCategories: {},
};

const namespaced = true;

export const courseList: Module<CourseState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
