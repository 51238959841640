import { AttributeNames, AttributesMap, IHasAttributes } from './Attributes';
import { ContentType, IBuilderContent } from './Content';
import { IFolderMember } from './Folder';
import { IGroupHasMembers } from './Group';
import { ProblemDefinition } from './Problem';

export type ProblemSetMember = ProblemSetDefinition | ProblemDefinition;

export interface IProblemSetMember extends IBuilderContent {
  contentType: ContentType.PROBLEM | ContentType.PROBLEM_SET;
  name?: string | null;
  createdAt: number;
  updatedAt: number;
}

export type ContentMemberType = ContentType.PROBLEM | ContentType.PROBLEM_SET;

export interface ProblemSetDefinition
  extends IFolderMember,
    IProblemSetMember,
    IGroupHasMembers,
    IHasAttributes {
  contentType: ContentType.PROBLEM_SET;
  problemSetType: ProblemSetType;
  properties?: ProblemSetProperties;
  attributes?: ProblemSetAttributes;
}

export type ProblemSetAttributes = Pick<
  AttributesMap,
  | AttributeNames.IS_ASSESSMENT
  | AttributeNames.IS_RESEARCH
  | AttributeNames.IS_CERTIFIED
>;

// FIXME: Finalize a list of SDK3 PS Types.
export enum ProblemSetType {
  LINEAR_COMPLETE_ALL = 'LINEAR_COMPLETE_ALL',
  RANDOM_COMPLETE_ALL = 'RANDOM_COMPLETE_ALL',
  STUDENT_CHOICE = 'SKIPPABLE',
  SKILL_BUILDER_RANDOM = 'SKILL_BUILDER_RANDOM',
  SKILL_BUILDER_LINEAR = 'SKILL_BUILDER_LINEAR',
  IF_THEN_ELSE = 'IF_THEN_ELSE',
  RANDOM_COMPLETE_ONE = 'RANDOM_COMPLETE_ONE',
  MULTI_PART_PROBLEM_SET = 'MULTI_PART_PROBLEM_SET',
}

// For now, default values will be hard-coded. Default properties will NOT be included in the definitions!
export interface ProblemSetProperties {
  // Defaults to 3
  CORRECT_IN_A_ROW?: number;
  // Defaults to 0
  TEST_OUT_COUNT?: number;
  // Defaults to 10
  DAY_LIMIT?: number;
  // Defaults to true
  STOP_ON_DAY_LIMIT?: boolean;
  // Defaults to 0
  TUTOR_TIMER?: number;
  // Defaults to null
  SKILL_BUILDER_ALIAS?: string | null;
  // Defaults to false
  AUTO_SCAFFOLDING?: boolean;
  // Defaults to false
  IS_MULTI_PART?: boolean;
  // Defaults to 50
  IF_CONDITION_CORRECT_PERCENT?: number;
  // Defaults to "none"
  EMPTY_BRANCH?: string;
  // Defaults to null
  HEADER?: string | null;
}
