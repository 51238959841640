var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center white rounded-t elevation-1 pa-2"},[_c('div',{staticClass:"text-h5 neutral--text text--darken-3 px-2",attrs:{"role":"heading"}},[_vm._v(" Detailed Report ")]),_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"4px"}},[_c('v-btn',{attrs:{"text":"","color":"bluePrimary700","data-cy":"expand-all-button"},on:{"click":function($event){_setup.expandAll = !_setup.expandAll}}},[(_setup.expandAll)?[_vm._v(" Collapse ")]:[_vm._v(" Expand ")],_vm._v(" All ")],2),_c('v-btn',{attrs:{"text":"","color":"bluePrimary700","data-cy":"print-button"},on:{"click":_setup.print}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-printer")]),_vm._v(" Print ")],1)],1)]),_c('v-data-table',{staticClass:"black--text",attrs:{"headers":_setup.headers,"items":_setup.rows,"loading":_vm.loading,"fixed-header":"","hide-default-footer":"","disable-pagination":"","data-cy":"student-details-table"},scopedSlots:_vm._u([{key:"item",fn:function({ item, headers, index }){return [_c('tr',{attrs:{"data-cy":`${item.actionType}-action-row`}},_vm._l((headers),function(header){return _c('td',{key:`${index}-${header.value}`,class:item.class},[(
              header.value == _setup.Header.ACTION_TYPE &&
              item.actionType == _setup.ActionType.PROBLEM_STARTED_ACTION
            )?_c('div',[_c('div',{staticClass:"d-flex align-center justify-space-between",staticStyle:{"gap":"4px"}},[_c('a',{on:{"click":function($event){return _setup.emit('openProblemSideSheet', item.xref)}}},[_vm._v(" "+_vm._s(item[header.value])+" ")]),(_setup.problemRows[item.xref].length > 1)?[(_setup.groups.includes(item.xref))?_c('v-icon',{attrs:{"color":"bluePrimary700","data-cy":"collapse-icon"},on:{"click":function($event){return _setup.collapse(item)}}},[_vm._v(" mdi-chevron-down ")]):_c('v-icon',{attrs:{"color":"bluePrimary700","data-cy":"expand-icon"},on:{"click":function($event){return _setup.expand(item)}}},[_vm._v(" mdi-chevron-up ")])]:_vm._e()],2)]):(header.value == _setup.Header.RESPONSE)?_c('div',{staticClass:"py-2"},[(
                item.actionType ===
                  _setup.ActionType.CAIT_MESSAGE_REQUESTED_ACTION ||
                item.actionType === _setup.ActionType.CAIT_MESSAGE_RESPONSE_ACTION
              )?[_c('div',{domProps:{"innerHTML":_vm._s(item[header.value])}})]:_vm._e(),_c(_setup.StudentResponseSDK3,{attrs:{"response":item[header.value]}})],2):(header.value == _setup.Header.TEACHER_FEEDBACK)?_c('div',{staticClass:"d-flex flex-column py-2",staticStyle:{"gap":"4px"}},[(item.score || item.score == 0)?_c('div',{staticClass:"font-weight-medium",attrs:{"data-cy":"score"}},[_vm._v(" Score: "+_vm._s(item.score)+"% ")]):_vm._e(),(item.feedback)?_c('div',{attrs:{"data-cy":"feedback"},domProps:{"innerHTML":_vm._s(item.feedback)}}):_vm._e(),(
                item.actionType == _setup.ActionType.STUDENT_SUBMISSION_ACTION &&
                item.prLog
              )?_c('div',[(item.score || item.score == 0)?_c('v-btn',{attrs:{"text":"","color":"bluePrimary700","data-cy":"edit-feedback-button"},on:{"click":function($event){return _setup.emit('openFeedbackSideSheet', item.prLog)}}},[_vm._v(" Edit Feedback ")]):_c('v-btn',{attrs:{"color":"bluePrimary700","dark":"","depressed":"","data-cy":"assess-button"},on:{"click":function($event){return _setup.emit('openFeedbackSideSheet', item.prLog)}}},[_vm._v(" Assess ")])],1):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(item[header.value]))])])}),0)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }