export enum AclSidType {
  USER = 'USER',
  GROUP = 'GROUP',
  ROLE = 'ROLE',
  AUTHENTICATION_STATE = 'AUTHENTICATION_STATE',
}

export enum AclSidAuthorizationStateType {
  DOES_NOT_MATTER = 'DOES_NOT_MATTER',
  MUST_BE_AUTHENTICATED = 'MUST_BE_AUTHENTICATED',
  MUST_NOT_BE_AUTHENTICATED = 'MUST_NOT_BE_AUTHENTICATED',
}

export enum AclResourceType {
  GROUP = 'GROUP',
  ASSIGNMENT = 'ASSIGNMENT',
  SDK3_FOLDER = 'SDK3_FOLDER',
  SDK3_PROBLEM = 'SDK3_PROBLEM',
  SDK3_PROBLEM_SET = 'SDK3_PROBLEM_SET',
  SDK3_TUTORING_STRATEGY = 'SDK3_TUTORING_STRATEGY',
  LEGACY_FOLDER = 'LEGACY_FOLDER',
  LEGACY_PROBLEM = 'LEGACY_PROBLEM',
  LEGACY_PROBLEM_SET = 'LEGACY_PROBLEM_SET',
}

export enum AclPermissionType {
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum AclEntryConflictResolutionType {
  LEAVE_AS_IS = 'LEAVE_AS_IS',
  REPLACE = 'REPLACE',
  REPORT = 'REPORT',
}

export interface SidPermission {
  granteeType: AclSidType;
  granteeXref: string;
  grantedPermissions: AclPermissionType[];
}

export interface AclEntryConflict {
  resourceXref: string;
  granteeXref: string;
  permissionsList: AclPermissionType[];
}

export interface IHasPermissions {
  resourceType: AclResourceType;
  permissions: AclPermissionType[];
}
