import { render, staticRenderFns } from "./FolderMenu.vue?vue&type=template&id=2b382c36&scoped=true&"
import script from "./FolderMenu.vue?vue&type=script&lang=ts&setup=true&"
export * from "./FolderMenu.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b382c36",
  null
  
)

export default component.exports