import {
  AclEntryConflict,
  AclEntryConflictResolutionType,
  AclResourceType,
  AclSidType,
  SidPermission,
} from '@/domain/Acls';
import { GroupDefinition } from '@/domain/Group';
import { coreAxios } from '@/plugins/axios';

const END_POINT = '/acls';

export interface AclsPermissionRequestBody {
  accessEntries: SidPermission[];
  replace?: boolean;
  propagate?: boolean;
  onConflict: AclEntryConflictResolutionType;
}

export const getAccessGroup = (xref: string): Promise<GroupDefinition> => {
  return coreAxios.get(`${END_POINT}/access-groups/${xref}`).then((res) => {
    return res.data;
  });
};

export const getResourcePermissions = (
  resourceType: AclResourceType,
  xref: string,
  controller?: AbortController
): Promise<Record<AclSidType, SidPermission[]>> => {
  return coreAxios
    .get(`${END_POINT}/resources/permissions/${resourceType}/${xref}`, {
      signal: controller?.signal,
    })
    .then((res) => {
      return res.data;
    });
};

export const updateResourcePermissions = (
  resourceType: AclResourceType,
  resourceXref: string,
  payload: AclsPermissionRequestBody
): Promise<AclEntryConflict[]> => {
  return coreAxios
    .patch(
      `${END_POINT}/resources/permissions/${resourceType}/${resourceXref}`,
      payload
    )
    .then((res) => {
      return res.data;
    });
};
