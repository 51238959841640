import { render, staticRenderFns } from "./ProblemOverview.vue?vue&type=template&id=cde516e4&scoped=true&"
import script from "./ProblemOverview.vue?vue&type=script&lang=ts&setup=true&"
export * from "./ProblemOverview.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./ProblemOverview.vue?vue&type=style&index=0&id=cde516e4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cde516e4",
  null
  
)

export default component.exports