import { QuickComment, QuickCommentFeedback } from '@/domain/QuickComment';
import { PartLogData, ProblemLog } from '@/domain/ReportData/StudentData';
import { isEqual } from 'lodash';
import { computed, Ref } from 'vue';

export type ScalePoint = 0 | 1 | 2 | 3 | 4;
export const scale: ScalePoint[] = [0, 1, 2, 3, 4];

// Undefined IF no score OR invalid score
export function getOpenResponseScore(
  problemLog?: ProblemLog
): ScalePoint | undefined {
  let score: number | undefined = problemLog?.continuousScore;
  if (score || score == 0) {
    score = score * 4;
    if (!scale.includes(score as ScalePoint)) {
      // Invalid score.
      score = undefined;
    }
  }
  return score as ScalePoint;
}

export function getOpenResponseAnswer(
  problemLog?: ProblemLog
): string | undefined {
  // FIXME: Figure out if/when we want to support multiple Open Response Answer Parts?
  // Will the response list always be of size 1?
  return problemLog?.partLogData?.[1].response?.[0];
}

export function getOpenResponseComment(
  problemLog?: ProblemLog
): string | undefined {
  return problemLog?.partLogData?.[1].teacherComment ?? undefined;
}

export function getSuggestedScore(
  quickComment?: QuickComment
): ScalePoint | undefined {
  let score = undefined;
  if (quickComment?.scoreIsAccurate) {
    score = quickComment?.suggestedScore;
  }
  return score as ScalePoint;
}

export function getSuggestedComments(
  scoreGiven?: ScalePoint,
  quickComment?: QuickComment
): string[] {
  let comments: QuickCommentFeedback[] = [];
  if (quickComment?.commentIsAccurate) {
    // Find feedback with the given score if any...
    if (scoreGiven || scoreGiven == 0) {
      comments = quickComment?.comments.filter(
        (comment) => comment.score == scoreGiven
      );
    }
    // If STILL NO feedback found, find feedback with the given suggested score if any...
    if (!comments?.length && quickComment?.scoreIsAccurate) {
      comments = quickComment?.comments.filter(
        (comment) => comment.score == quickComment?.suggestedScore
      );
    }
  }
  return comments.map((comment) => comment.text);
}

interface OpenResponseLogic {
  scoreGiven: Ref<ScalePoint | undefined>;
  response: Ref<string | undefined>;
  commentGiven: Ref<string | undefined>;
  suggestedScore: Ref<ScalePoint | undefined>;
  suggestedComments: Ref<string[] | undefined>;
  updateScore: (score: ScalePoint) => void;
  updateComment: (comment: string) => void;
}

export interface OpenResponseEvents {
  (
    event: 'updateLog',
    modifiedFields: Record<
      number,
      Partial<Pick<PartLogData, 'continuousScore' | 'teacherComment'>>
    >
  ): void;
}

export function useOpenResponseLogic(
  problemLog?: Ref<ProblemLog | undefined>,
  quickComment?: Ref<QuickComment | undefined>,
  emit?: OpenResponseEvents
): OpenResponseLogic {
  const scoreGiven = computed((): ScalePoint | undefined => {
    return getOpenResponseScore(problemLog?.value);
  });

  const response = computed((): string | undefined => {
    return getOpenResponseAnswer(problemLog?.value);
  });

  const commentGiven = computed((): string | undefined => {
    return getOpenResponseComment(problemLog?.value);
  });

  const suggestedScore = computed((): ScalePoint | undefined => {
    return getSuggestedScore(quickComment?.value);
  });

  const suggestedComments = computed((): string[] => {
    return getSuggestedComments(scoreGiven.value, quickComment?.value);
  });

  function updateScore(score: ScalePoint): void {
    if (scoreGiven.value != score) {
      const continuousScore = score / 4;
      emit?.('updateLog', { 1: { continuousScore } });
    }
  }

  function updateComment(comment: string): void {
    if (!comment && !commentGiven.value) {
      return;
    } else if (!isEqual(comment, commentGiven.value)) {
      if (comment) {
        emit?.('updateLog', { 1: { teacherComment: comment } });
      } else {
        // Reset?
        emit?.('updateLog', { 1: { teacherComment: null } });
      }
    }
  }

  return {
    scoreGiven,
    response,
    commentGiven,
    suggestedScore,
    suggestedComments,
    updateScore,
    updateComment,
  };
}
