import { MutationTree } from 'vuex';
import { CourseState } from './types';
import { CourseDefinition } from '@/domain/Course';
import { CategoryDefinition } from '@/api/core/course.api';
import Vue from 'vue';

export const mutations: MutationTree<CourseState> = {
  setHasDownloaded(state: CourseState, flag: boolean) {
    state.hasDownloaded = flag;
  },
  setIsDownloading(state: CourseState, flag: boolean) {
    state.isDownloading = flag;
  },
  setCourseList(state: CourseState, newList: Array<CourseDefinition>) {
    state.courses = newList;
  },
  setCourseCategories(
    state: CourseState,
    entry: { xref: string; categories: CategoryDefinition[] }
  ) {
    Vue.set(state.courseCategories, entry.xref, entry.categories);
  },
};
